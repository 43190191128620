/*
  Overrides for desktop embeddedWidgets.
  Start all selectors with #embeddedWidgetContainer.embeddedWidget.mw-desktop
  E.g.,
  #embeddedWidgetContainer.embeddedWidget.mw-desktop #initiateSharingDialog .initiateSharingInviteeListContainer .initiateSharingInviteeList {
    background-color: yellow;
  }
*/
div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.modal-header button.btn-close.cancelSharing {
  display: none;
}

div#embeddedWidgetContainer.embeddedWidget.mw-resizable-desktop div#initiateSharingDialog div.modal-header button.btn-close.cancelSharing {
  display: none;
}

div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.modal-dialog > div.modal-content{
  border: none !important;
  border-radius: 0px;
}

div#embeddedWidgetContainer.embeddedWidget.mw-resizable-desktop div#initiateSharingDialog div.modal-dialog > div.modal-content {
  border: none !important;
  border-radius: 0px;
}

div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.linkStatsContainer {
  display: none !important;
}

div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.modal-content > div.modal-body.links {
  height: 178px;
}

div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.modal-content > div.modal-body { height: 300px; }
div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.modal-content > div.modal-body #sharing_comments,
div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.modal-content > div.modal-body #commentLength { display: none; }
div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.modal-content > div.modal-body input#emailInput { width: 370px; }
div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.modal-content > div.modal-body #addInviteeButton { margin-top: -64px; margin-right: -5px; }
div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.modal-content > div.modal-body #selectedSharePermission {margin-left: 3px; margin-right: 5px; }
div#embeddedWidgetContainer.embeddedWidget.mw-desktop div#initiateSharingDialog div.modal-content > div.modal-body .initiateSharingInviteeListContainer { margin-top: -4px; }
