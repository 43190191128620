/*
-----------------------------------
TOC
-----------------------------------

Global Resets:............Reset Element Base Styles

Page Globals:.............Styling of HTML and BODY

Bootstrap and Other CSS Overrides:......Changes to base-Bootstrap and MW CSS

Structure:................Site structural components

Site Elements:............Re-usable site elements

Page/Element Specific:....Page, element or flow Specific CSS

Section Specific:.........Styles for Login and Sub Page Templates

Dialog Specific:..........Specific styles for dialogs

Create Resource Wizard:...Specific styles for wizard

Inline Validation.........Specific styles for error display inline

Notifications, Warnings and Modals:...Specific styles for said items

Embedded Widgets:.........Specific styles for embedded widgets and testers

*/


/* ------------------------------------
$ Global Resets
------------------------------------ */

/* ------------------------------------
$ Page Globals
------------------------------------ */

/* ------------------------------------
$ Bootstrap and other CSS Overrides
------------------------------------ */

/* ------------------------------------
Structure
------------------------------------ */

/* ------------------------------------
$ Site Elements
------------------------------------ */

/* ------------------------------------
$ Page/Element Specific
------------------------------------ */

/* ------------------------------------
$ Section Specific
------------------------------------ */

/* ------------------------------------
$ Dialogs Specific
------------------------------------ */

/* ------------------------------------
$ Create Resource Wizard
------------------------------------ */

  div.wizardBody {width: 100% !important; padding: 0px 0px;}

/* ------------------------------------
$ Inline Validation
------------------------------------ */

/* ------------------------------------
$ Notifications, Warnings and Modals
------------------------------------ */

/* ------------------------------------
$ Embedded Widgets
------------------------------------ */
