/* ---- Roboto Font ---- */
  /* Roboto 400 */
  @font-face {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/roboto-latin-normal-400.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: Roboto, sans-serif;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/roboto-latin-italic-400.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* Roboto 500 */
  @font-face {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/roboto-latin-normal-500.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: Roboto, sans-serif;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/roboto-latin-italic-500.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* Roboto 700 */
  @font-face {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/roboto-latin-normal-700.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  } 
  @font-face {
    font-family: Roboto, sans-serif;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/roboto-latin-italic-700.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }