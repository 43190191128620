/*
-----------------------------------
TOC
-----------------------------------

Global Resets:............Reset Element Base Styles

Page Globals:.............Styling of HTML and BODY

Bootstrap and Other CSS Overrides:......Changes to base-Bootstrap and MW CSS

Structure:................Site structural components

Site Elements:............Re-usable site elements

Page/Element Specific:....Page, element or flow Specific CSS

Section Specific:.........Styles for Login and Sub Page Templates

Dialog Specific:..........Specific styles for dialogs

Create Resource Wizard:...Specific styles for wizard

Inline Validation.........Specific styles for error display inline

Notifications, Warnings and Modals:...Specific styles for said items

Embedded Widgets:.........Specific styles for embedded widgets and testers

*/


/* ------------------------------------
$ Global Resets
------------------------------------ */

/* ------------------------------------
$ Page Globals
------------------------------------ */

/* ------------------------------------
$ Bootstrap and other CSS Overrides
------------------------------------ */

  div.header-actions { width: 100px; }

/* ------------------------------------
Structure
------------------------------------ */

/* ------------------------------------
$ Site Elements
------------------------------------ */

  div.attribute { padding-right: 10px;}
  div.attribute.context-menu { padding: 0px; }
  div.attribute.resource-primary-cta > span > button.icon-attribute.cta-btn::after { content: ""; }
  div.attribute.resource-primary-cta > span > button.icon-attribute.cta-btn { padding: 5px; }

/* ------------------------------------
$ Page/Element Specific
------------------------------------ */

/* ------------------------------------
$ Section Specific
------------------------------------ */

/* ------------------------------------
$ Dialogs Specific
------------------------------------ */

/* ------------------------------------
$ Create Resource Wizard
------------------------------------ */

  div.wizardSection { width: 100%; }
  div.wizardBody {width: 100% !important;}
  div#wizardButtons.buttonContainer.stepComponentContainer {position: static; width: auto; padding: 0px;}

/* ------------------------------------
$ Inline Validation
------------------------------------ */

/* ------------------------------------
$ Notifications, Warnings and Modals
------------------------------------ */

  /* Global Alerts */
  .importantMessage,
  .importantMessageInfo,
  .importantMessageError { width:60%; left: max(350px, calc(50% - 30%)); }

/* ------------------------------------
$ Embedded Widgets
------------------------------------ */
