/*
-----------------------------------
TOC
-----------------------------------

Global Resets:............Reset Element Base Styles

Page Globals:.............Styling of HTML and BODY

Bootstrap and Other CSS Overrides:......Changes to base-Bootstrap and MW CSS

Structure:................Site structural components

Site Elements:............Re-usable site elements

Page/Element Specific:....Page, element or flow Specific CSS

Section Specific:.........Styles for Login and Sub Page Templates

Dialog Specific:..........Specific styles for dialogs

Create Resource Wizard:...Specific styles for wizard

Inline Validation.........Specific styles for error display inline

Notifications, Warnings and Modals:...Specific styles for said items

Embedded Widgets:.........Specific styles for embedded widgets and testers

Responsive:...............Specific styles for different screen widths

*/


/* ------------------------------------
$ Global Resets
------------------------------------ */

/* ------------------------------------
$ Page Globals
------------------------------------ */

/* ------------------------------------
$ Bootstrap and other CSS Overrides
------------------------------------ */

/* ------------------------------------
Structure
------------------------------------ */

  /* ---- Header ---- */
  .headerContainer { position: relative; }
  /* [ Additional Styles: SM + XS ] */
  .previewBannerContainer { top: 1px; font-size: 12px; right: 50px; }

  /* Header: Mobile Subnav */
    /* ---- Section Header ---- */
    .section_header_content .add_font_automate { font-size: 25px !important; }
    #section_header_title { width: calc(100% - 64px); float: left; }
    .cloudCenterLogo { font-weight: 400; font-size: 24px; font-family: Roboto, Helvetica Neue,Helvetica,Arial,sans-serif; color: #fff;}

/* ------------------------------------
$ Site Elements
------------------------------------ */

  /* ---- Footer ---- */
  .mainFooter { padding: 16px 0px; }
  /* Footer: Home Page */
  #loginContainer .mainFooter { padding: 16px 0; }

/* ------------------------------------
$ Page/Element Specific
------------------------------------ */

/* ------------------------------------
$ Section Specific
------------------------------------ */

  /* Embedded Login */
  iframe.authIframe { width: 100%; height: 350px; }
  /* ---- Login Page ---- */
  .leftRightContainer { min-height: calc(100vh - 97px - 74px); }
  /* Countdown Timer */
  div.statusAndCountdownContainer {
    position: unset;
    top: unset;
    padding-top: 4px;
  }
  div.timeoutDropdownContainer {
    position: unset;
    top: unset;
    padding-top: 4px;
  }

  /* Page load and create button spinner before createWizardStepsContainer */
  /* margin-top, margin-right, margin-bottom, and margin-left */
  div#pageNavPreloadSpinnerContainer,
  div#initialCreateButtonClickSpinner {margin: 20px 0px 0px 95px; }


/* ------------------------------------
$ Dialogs Specific
------------------------------------ */

  #viewFileDialog .modal-dialog { width: calc(100% - 30px); }

/* ------------------------------------
$ Create Resource Wizard
------------------------------------ */

/* ------------------------------------
$ Inline Validation
------------------------------------ */

  /* Login Error (Home Page) */
  .loginErrorWrapper { width: calc(100% - 30px); margin: 0 auto; }

/* ------------------------------------
$ Notifications, Warnings and Modals
------------------------------------ */

  /* Global Alerts */
  .importantMessage,
  .importantMessageInfo,
  .importantMessageError { width:100%; left: max(225px, calc(50% - 34%)); }

/* ------------------------------------
$ Embedded Widgets
------------------------------------ */

/* ------------------------------------
$  Reponsive
------------------------------------ */

  @media only screen and (max-width: 767px) {
    /* action buttons */
    .horizontalIconContainer [class*="Icon"] {
      width: 16px; height: 16px; background-repeat: no-repeat; background-position: center center;
      background-size: contain; display: inline-block; white-space: nowrap;
    }
  }

  @media only screen and (min-width: 731px) {
    .importantMessage,
    .importantMessageInfo,
    .importantMessageError { width:68%; left: max(225px, calc(50% - 34%)); top: 55px; }
  }

  @media only screen and (max-width: 730px) {
    .importantMessage,
    .importantMessageInfo,
    .importantMessageError { width:68%; left: 50px; top: 0px; }
  }

  /* Table: 1 Column Card Layout */
  @media screen and (max-width:580px) {
    .collection-container { display: grid; grid-template-columns: 1fr; grid-gap: 0; }
    .cred-dropdown-mobile { background-color: white !important; margin: 0px; width: 100%; padding: 0px 0px 20px 0px; }
    .li-about-page:hover,
    .li-management-page:hover,
    .li-cred-page:hover { background-color: #f6f6f6; }
    .cred-type-menu-options { width: 100%; text-align: left; font-size: 14px; color: #3175A4; }
    .hr-margin{ margin-top: 5px; margin-bottom: 5px; }
    .attribute-about-page::before,
    .attribute-management-page::before,
    .attribute-credential-page::before { content:  attr(data-mobile-name) !important; min-height: 0px !important; color: black; }
    .attribute-about-page,
    .attribute-management-page,
    .attribute-credential-page { display: grid; grid-template-columns: minmax(20px, 100px) 1fr; min-height: 16px; }
    .threedot-about-page-mobile,
    .threedot-management-page-mobile,
    .threedot-credential-page-mobile { margin-top: 0px; }
    .margin-mobile { margin-top: 10px; }
    .about-page-font-mobile,
    .management-page-font-mobile,
    .cred-page-font-mobile { font-size: small; color: gray; margin-top: 4px; }
    .about-page-title-mobile,
    .management-page-title-mobile,
    .cred-page-title-mobile { margin-bottom: 11px; }
    .item-container-for-about-page,
    .item-container-for-management-page,
    .item-container-for-cred-page { border-bottom: hidden; border-top: 2px solid rgb(220, 220, 220); border-left: hidden; border-right: hidden; }
    .credentialInstanceContainer { border: hidden !important; }
  }

  @media only screen and (min-width: 401px) and (max-width: 480px) {
    /* action buttons */
    .horizontalIconContainer [class*="Icon"] { width: 24px; height: 24px; background-repeat: no-repeat; background-position: center center; background-size: contain; display: inline-block; white-space: nowrap; }
    .actionIconContainer { padding-top: 15px; padding-bottom: 10px; }
  }

  @media only screen and (min-width: 371px) and (max-width: 400px) {
    /* action buttons */
    .horizontalIconContainer [class*="Icon"] { width: 22px; height: 22px; background-repeat: no-repeat; background-position: center center; background-size: contain; display: inline-block; white-space: nowrap; }
    .actionIconContainer { padding-top: 15px; padding-bottom: 10px; }
  }

  @media only screen and (max-width: 380px) {
    .mainFooter { font-size: 10.5px; }
    .footernav li { font-size: 10.5px; line-height: 1.2; }
    .footernav a { padding: 0 4px; }
    .footernav li:first-child a { padding-left: 0; }
    .footernav li:last-child a { padding-right: 0; }
    .leftRightContainer { min-height: calc(100vh - 97px - 65px); }
    .horizontalIconContainer { padding-right: 7px; }
  }

  @media only screen and (max-width: 370px) {
    /* action buttons */
    .horizontalIconContainer [class*="Icon"] { width: 16px; height: 16px; background-repeat: no-repeat; background-position: center center; background-size: contain; display: inline-block; white-space: nowrap; }
    .actionIconContainer { padding-top: 15px; padding-bottom: 10px; }
  }

  @media only screen and (max-width: 330px) {
    div.resourceContainer > div.clusterContainer,
    div.resourceContainer > div.matlabContainer { min-height: 125px; background-color: #efefef; border: 1px solid #ddd; border-radius: 10px; position: relative;}
    div.resourceContainer > div.resourceTitleBar { font-size: 14px; font-weight: 400; font-family: Helvetica,Arial,sans-serif; color: #666; margin: 25px 0px;}
  }

  @media only screen and (max-width: 321px) {
    .cloudCenterLogo { font-size: 22px; }
  }

  @media only screen and (max-width: 281px) {
    .cloudCenterLogo { font-size: 18px; }
    div#header_mobile .appTitle { padding-top: 18px; }
  }

  @media only screen and (max-width: 280px) {
    html body { height: 100%; min-width: 220px; padding: 0; margin: 0; background: #333; font-family: Arial, Helvetica, sans-serif; }
    div.resourceTitleBar > button.startNewMatlabButton { margin-left: 0px; }
  }
