/*
min-width: 990 and no max-width
-----------------------------------
TOC
-----------------------------------

Global Resets:............Reset Element Base Styles

Page Globals:.............Styling of HTML and BODY

Bootstrap and Other CSS Overrides:......Changes to base-Bootstrap and MW CSS

Structure:................Site structural components

Site Elements:............Re-usable site elements

Page/Element Specific:....Page, element or flow Specific CSS

Section Specific:.........Styles for Login and Sub Page Templates

Dialog Specific:..........Specific styles for dialogs

Create Resource Wizard:...Specific styles for wizard

Inline Validation.........Specific styles for error display inline

Notifications, Warnings and Modals:...Specific styles for said items

Embedded Widgets:.........Specific styles for embedded widgets and testers

*/


/* ------------------------------------
$ Global Resets
------------------------------------ */

/* ------------------------------------
$ Page Globals
------------------------------------ */

/* ------------------------------------
$ Bootstrap and other CSS Overrides
------------------------------------ */

  #headernav { padding-top: 18px; }
  #headernav > li { padding-left: 7px; font-size: 18px; line-height: 1.15; }
  #headernav > li > a { padding: 0 4px; margin: 0; color: #fff; font-weight: normal; line-height: 0.92; font-size: 13px; }
  #headernav > li > a:hover { background-color: transparent; }
  #headernav_collapse .navbar-nav > .open > a,
  #headernav_collapse .navbar-nav > .open > a:hover,
  #headernav_collapse .navbar-nav > .open > a:focus { background-color: transparent; outline: none !important;}
  #headernav_collapse li.headernav_login { border-left: none; }
  #headernav_collapse li.headernav_login > a { padding-right: 0; padding-left: 4px; padding-top: 3px; margin-right: 20px; position: relative; }
  #headernav_collapse li.headernav_login > a:focus {outline: none !important;}

/* ------------------------------------
Structure
------------------------------------ */

/* ------------------------------------
$ Site Elements
------------------------------------ */

  .item-container,
  .item-container-for-about-page,
  .item-container-for-management-page,
  .item-container-for-cred-page { display: table-row; width: 100%; }
  li.item.item-container { height: 50px; }
  li.item.item-container.table-header { height: auto; }
  li.table-header div.attribute { vertical-align: top; }
  .hr-margin { margin-top: 10px; margin-bottom: 10px; }
  li.item-container-for-about-page > div.attribute-container,
  li.item-container-for-management-page > div.attribute-container,
  li.item-container-for-cred-page > div.attribute-container { display: table-cell; border-bottom: 1px solid #d8d8d8; vertical-align: middle; }
  li.item-container-for-about-page:first-child > div.attribute-container,
  li.item-container-for-management-page:first-child > div.attribute-container,
  li.item-container-for-cred-page:first-child > div.attribute-container { display: table-cell; border-bottom: 2px solid #d8d8d8; background-color: #fff; vertical-align: bottom; }
  li.item-container-for-about-page > div.attribute-container > div.attribute,
  li.item-container-for-management-page > div.attribute-container > div.attribute,
  li.item-container-for-cred-page > div.attribute-container > div.attribute { border: none !important; }
  li.item-container-for-about-page:first-child > div.attribute-container:first-child,
  li.item-container-for-management-page:first-child > div.attribute-container:first-child,
  li.item-container-for-cred-page:first-child > div.attribute-container:first-child { border-bottom: 2px solid #d8d8d8; }
  li.emptyTable { white-space: nowrap; }

  /* Definition of wrapping column width for attribute groups. */
  .attribute-heading { text-align: left; }
  .resource-menu-mobile { display: none; }
  ul.dropdown-menu > div.mobileCover { display: none; }
  ul.dropdown-menu > div.menuContent { background-color: white; padding: 0px; }

  /* In order to maximize row lines, only display one line for a cell */
  .attribute {
    border-right: 0px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    padding: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #6f6f6f;
    display: table-cell;
    align-items: center;
    vertical-align: middle;
  }
  div.attribute button { background-color: inherit; border: none; }
  div.attribute.resource-primary-cta button { border: 1px solid #0076A8; border-radius: 5px; color: #0076A8; }
  div.attribute.resource-primary-cta button div.smallIcon {display: inline-block; vertical-align: middle; }
  div.attribute.resource-primary-cta button span { vertical-align: middle; font-size: 14px; font-family: Roboto, sans-serif; position: relative; top: 1px; }
  .attribute-about-page,
  .attribute-management-page,
  .attribute-credential-page { padding: 7px; }
  .collection-container > .item-container:first-child { background-color: white; font-weight: bold; }
  .collection-container .spinnerIconClean.smallIcon { margin: 8px 0px; }
  .collection-container .dropdown-menu { left: auto; right: 0px; }
  .icon-container { background-color:inherit; text-align: left; }
  .resource-menu ul { padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px; }

  .menu-container { background-color: inherit; text-align: unset; overflow: unset; white-space: unset; text-overflow: unset; }
  .menu-container ul.dropdown-menu .icon-attribute::after { content: attr(data-name); white-space: nowrap; color: #212121; font-size: 14px; }
  .menu-container ul.dropdown-menu li:hover { background-color: #f6f6f6; }
  .menuContent li > span { line-height: 22px; }
  .menu-container ul.dropdown-menu li span { min-width: 160px; text-align: unset; font-size: 14px; padding: 0px; white-space: nowrap; }
  .menu-container ul.dropdown-menu li button { font-family: Roboto, sans-serif; white-space: nowrap; color: #212121 !important; font-size: 14px; text-align: left; width: 100%; }
  .menu-container ul.credential-action-dropdown-menu.dropdown-menu li:hover { cursor: pointer; }
  .menu-container ul.credential-action-dropdown-menu.dropdown-menu li.disabled:hover { cursor: not-allowed; }
  li.item-container-for-about-page > div.dropdown.menu-container,
  li.item-container-for-management-page > div.dropdown.menu-container,
  li.item-container-for-cred-page > div.dropdown.menu-container { border-bottom: 1px solid #d8d8d8; width: 60px; }
  li.item-container-for-about-page:first-child > div.dropdown.menu-container,
  li.item-container-for-management-page:first-child > div.dropdown.menu-container,
  li.item-container-for-cred-page:first-child > div.dropdown.menu-container { border-bottom: 2px solid #d8d8d8; background-color: #fff; }

  div.attribute.resource-primary-cta > span > button.icon-attribute.cta-btn { font-family: Roboto, sans-serif; font-size: 14px; color: #3175A4; border: none; padding: 2px; }
  div.attribute.resource-primary-cta > span > button.icon-attribute.cta-btn#connectBtn,
  div.attribute.resource-primary-cta > span > button.icon-attribute.cta-btn::after { content: ""; white-space: nowrap; color: #3175A4; position: relative; top: 1px; }
  .item-container:hover { background-color: #f5f5f5; }

  /* Center header labels */
  .collection-container > .item-container:first-child .attribute {
    display: table-cell;
    align-items: unset;
    justify-content: unset;
    text-overflow: initial;
    overflow: auto;
    white-space: normal;
    color: #1a1a1a;
    border-bottom: 2px solid #d8d8d8;
  }
  li.item-container-for-about-page:first-child > div.attribute-container > div.attribute
  li.item-container-for-management-page:first-child > div.attribute-container > div.attribute
  li.item-container-for-cred-page:first-child > div.attribute-container > div.attribute { border-bottom: none; }

/* ------------------------------------
$ Page/Element Specific
------------------------------------ */

/* ------------------------------------
$ Section Specific
------------------------------------ */

/* ------------------------------------
$ Dialogs Specific
------------------------------------ */

/* ------------------------------------
$ Create Resource Wizard
------------------------------------ */

/* ------------------------------------
$ Inline Validation
------------------------------------ */

/* ------------------------------------
$ Notifications, Warnings and Modals
------------------------------------ */

/* ------------------------------------
$ Embedded Widgets
------------------------------------ */
