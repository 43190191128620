/*
-----------------------------------
TOC
-----------------------------------

Global Resets:............Reset Element Base Styles

Page Globals:.............Styling of HTML and BODY

Bootstrap and Other CSS Overrides:......Changes to base-Bootstrap and MW CSS

Structure:................Site structural components

Site Elements:............Re-usable site elements

Page/Element Specific:....Page, element or flow Specific CSS

Section Specific:.........Styles for Login and Sub Page Templates

Dialog Specific:..........Specific styles for dialogs

Create Resource Wizard:...Specific styles for wizard

Inline Validation.........Specific styles for error display inline

Notifications, Warnings and Modals:...Specific styles for said items

Embedded Widgets:.........Specific styles for embedded widgets and testers

*/


/* ------------------------------------
$ Global Resets
------------------------------------ */

/* ------------------------------------
$ Page Globals
------------------------------------ */

/* ------------------------------------
$ Bootstrap and other CSS Overrides
------------------------------------ */

  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand { margin-left: 0; }
  .navbar-nav .open .dropdown-menu { width: auto; margin-top: 0; border: 0; background-color: transparent; box-shadow: none; float: none; position: static; }
  /* Per Coderwall: https://coderwall.com/p/wpjw4w/change-the-bootstrap-navbar-breakpoint */
  .navbar-header { float: none; }
  .navbar-left,
  .navbar-right { float: none !important; }
  .navbar-toggle { display: block; }
  .navbar-fixed-top { top: 0; }
  .navbar-collapse.collapse:not(.show) { display: none !important; }
  .navbar-nav { float: none !important; }
  .navbar-nav > li { padding: 0px; width: 100%; line-height: 22px; }
  .navbar-nav > li.headernav_help { padding: 0px; }
  .collapse.show { display: block !important; }

  /* MW Overrides for Coderwall */
  #subnav .navbar-collapse.collapse:not(.show) { display: block !important; }
  #subnav .navbar-nav { float: left !important; }
  #subnav .navbar-nav > li { float: left; }

  .navbar-nav { margin: 0px; }

/* ------------------------------------
Structure
------------------------------------ */

  /* ---- Header ---- */
  .headerContainer { position: relative; }
  .previewBannerContainer { font-size: 22px; }

/* ------------------------------------
$ Site Elements
------------------------------------ */

  /* action buttons */
  .horizontalIconContainer [class*="Icon"] { width: 24px; height: 24px; background-repeat: no-repeat; background-position: center center; background-size: contain; display: inline-block; white-space: nowrap; }
  .actionIconContainer { padding-top: 15px; padding-bottom: 10px; }

/* ------------------------------------
$ Page/Element Specific
------------------------------------ */

/* ------------------------------------
$ Section Specific
------------------------------------ */

/* ------------------------------------
$ Dialogs Specific
------------------------------------ */

/* ------------------------------------
$ Create Resource Wizard
------------------------------------ */

/* ------------------------------------
$ Inline Validation
------------------------------------ */

/* ------------------------------------
$ Notifications, Warnings and Modals
------------------------------------ */

  /* Global Alerts */
  .importantMessage,
  .importantMessageInfo,
  .importantMessageError { width:68%; left: max(225px, calc(50% - 34%)); }

/* ------------------------------------
$ Embedded Widgets
------------------------------------ */
