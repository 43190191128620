/* Form: Alerts */
.alert {
  padding: 13px 8px 13px 42px;
  border: none;
  border-radius: 0;
  color: #212121;
  font-size: 15px;
	margin-bottom: 5px;
	margin-right: 5px;
}
.alert h3 {
  font: normal bold 15px/1.11 "Roboto", sans-serif;
}
.alert .alert_heading {
  margin-bottom: 13px;
  font-size: 15px;
  line-height: 1.11;
}
.alert p,
.alert ul,
.alert ol {
  margin-bottom: 10px;
}
.alert .alert_icon {
  margin: -5px 0 0 -34px;
  font-size: 24px !important;
  float: left;
  line-height: 1;
}
.alert > *:last-child,
.alert > *:last-child > *:last-child,
.alert > *:last-child > *:last-child > *:last-child {
  margin-bottom: 0;
}
.alert .btn-close span {
  font-size: 21px;
}
.alert-danger {
  border: 1px solid #b7312c;
  background: #faf0f0;
}
.alert-danger .alert_icon {
  color: #b7312c;
}
.alert-info {
  border: 1px solid #0076a8;
  background: #e2f0ff;
}
.alert-info .alert_icon {
  color: #0076a8;
}
.alert-success {
  border: 1px solid #008013;
  background: #f5fff2;
}
.alert-success .alert_icon {
  color: #008013;
}
.alert-warning {
  border: 1px solid #c04c0b;
  background: #fef9ed;
}
.alert-warning .alert_icon {
  color: #c04c0b;
}
/* Dismissible Alerts */
.alert-dismissible {
  padding-right: 35px;
}
/* Alert Links : See Link Colors Above */
.alert .alert-link {
  font-weight: normal;
}

.alert-dismissable .btn-close { position: absolute; right: 8px; height: 5px; width: 5px; opacity: .2; }

.alert ul,
.alert ol { padding-left: 20px; padding-right: 20px; }

.alert ul li,
.alert ol li {
	word-break: break-word;
}

@media only screen and (max-width: 991px) {
  .alert ul li,
  .alert ol li {
  	word-break: break-all;
  }
}

.alert ul li.notificationMessage,
.alert ol li.notificationMessage {
	padding-top: 13px;
}

.alert ul li.notificationLink,
.alert ol li.notificationLink {
	padding-top: 0px;
}

.alert.collapsed { width: 100px; padding: 13px 8px 8px 42px; }
.alert.collapsed p.alert_heading { padding: 0px; margin: 0px; white-space: nowrap; }

a.collapse_toggle { padding-top: 8px; }

div.notificationsWrapper { display: flex; flex-direction: column; }
div.notificationsWrapper .alert-danger { order: 1; }
div.notificationsWrapper .alert-danger.resource-message { order: 0; }
div.notificationsWrapper .alert-warning { order: 2; }
div.notificationsWrapper .alert-warning.resource-message { order: 0; }
div.notificationsWrapper .alert-success { order: 3; }
div.notificationsWrapper .alert-info { order: 4; }
div.notificationsWrapper div.alert ul.notificationMessageList li.notificationMessage { list-style: disc; font-weight: 400; }
div.notificationsWrapper div.alert ul.notificationMessageList li.notificationLink { text-indent: 20px; }

button[class*=icon-], span[class*=icon-]:not(.glyphicon) {
    border: 1px solid transparent;
    display: inline-block;
    font-family: 'mathworks', sans-serif;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-variant: normal;
    font-weight: normal;
    text-transform: none;
    speak: none;
}

span.severityLabel { vertical-align: top; font-weight: 700; }
.alert.collapsed span.severityLabel { display: none; }

span.hideShowText[class*=icon-arrow-open-] {
	font-size: 24px;
	font-weight: bold;
	vertical-align: middle;
}

.icon-menu:before { content: "" !important; }

button.btn.collapse_toggle { padding: 0px; background-color: inherit; border: none; }
button.btn.collapse_toggle:focus { outline:2px solid #268cdd !important; outline-offset:2px; }

.alert.resource-message { position: absolute; top: 0px; z-index: 100; }
.alert-dismissable.collapsed ~ .alert.resource-message { top: 50px; }
.alert-dismissable:not(.collapsed) ~ .alert.resource-message { position: static; }
.alert.resource-message:has(+ .alert-dismissable) { top: 50px; }
.alert.resource-message:has(+ .alert-dismissable:not(.collapsed)) { position: static; }
