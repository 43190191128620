div#unsupportedBrowserContainer {
    background-color: #fff;
    color: #000;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding: 0px;
}

div#unsupportedBrowserContainer > div#unsupportedBrowser {
    font-size: 15px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
}

div#unsupportedBrowserContainer > div#unsupportedBrowser img {
  height: 50px;
  width: 225px;
}

div#unsupportedBrowserContainer > div#unsupportedBrowser p.link {
  font-size: 15px;
}

div#unsupportedBrowserContainer > div#unsupportedBrowser > div.content_module > div > img {
  padding-left: 25px;
}

div#unsupportedBrowserContainer > div#unsupportedBrowser > div.content_module > div#unsupportedErrorText {
  padding: 25px;
}

div#unsupportedBrowserContainer > div#unsupportedBrowser > div.content_module div.bluebar {
  background-color: rgb(0, 118, 168) !important;
  width: 100%;
  height: 52px;
  margin: 0px 0px 0px 0px;
  padding: 0px 25px;
}

div#unsupportedBrowserContainer .footer {
    position: absolute;
    bottom: 0px;
    background-color: #000;
    left: 0;
    right: 0;
    color: #fff;
    padding: 25px;
}
