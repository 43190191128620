@import '../../../../dependencies/mw-ui-themes/stylus/fallback.variables';

/*******************************
    ProgressIndicator
*******************************/

    mw-progress-indicator {

        --mw-height-progress-indicator-xsmall: 16px;
        --mw-width-progress-indicator-xsmall: 16px;

        --mw-height-progress-indicator-default: 16px;
        --mw-width-progress-indicator-default: 16px;

        --mw-height-progress-indicator-small: 24px;
        --mw-width-progress-indicator-small: 24px;

        --mw-height-progress-indicator-medium: 32px;
        --mw-width-progress-indicator-medium: 32px;

        --mw-height-progress-indicator-large: 48px;
        --mw-width-progress-indicator-large: 48px;
        
        --mw-height-progress-indicator-xlarge: 64px;
        --mw-width-progress-indicator-xlarge: 64px;

         
        display: inline-block;
        position: relative;
        vertical-align: middle;
        background-color: transparent;
        width: var(--mw-width-progress-indicator-default);
        height: var(--mw-height-progress-indicator-default);

        .progressIndicatorSvg {
            position: absolute;
        }

        .mw-stroke-default-color {
            stroke: var(--mw-backgroundColor-info, $mw-backgroundColor-info);
        }
        .mw-progress-indicator-border {
            stroke: var(--mw-backgroundColor-tertiary,$mw-backgroundColor-tertiary);

        }

        .outer-group {
            mix-blend-mode:multiply;
            fill: var(--mw-borderColor-primary, $mw-borderColor-primary );
            }
        .mw-progress-indicator-gutter {
            fill: none;
            stroke: var( --mw-backgroundColor-selected,$mw-backgroundColor-selected);
            stroke-opacity: 1;
        }
           .mw-progress-indicator {
            fill: none;
            stroke-dashoffset: 0;
            transition: stroke-dasharray 0.15s linear;
            transform: rotate(-0.25turn);
            isolation:isolate;
        }

        @keyframes mw-progress-indicator-IndeterminateAnimation {
            from {
                transform: rotate(0deg);
            }
            to {
               transform: rotate(360deg);
            }
        }

        /*
        * Commented as there is no current usecase for displaying text
        */
        // .mwProgressSpinnerText {
        //     color: rgba(0, 0, 0, .75);
        //     position: absolute;
        //     top: 1;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-color: transparent;
        //     font-weight: normal;
        //     text-align: center;
        //     font-size: 9px;
        //     line-height: 30px;
        // }

        &[indeterminate] svg {
            animation: mw-progress-indicator-IndeterminateAnimation 1.3s infinite linear;
        }
        &[value="0"] .mw-progress-indicator {
            display: none;
        }

        &[size="xsmall"] {
            width:  var(--mw-width-progress-indicator-xsmall);
            height: var(--mw-height-progress-indicator-xsmall);
        }
        &[size="small"] {
            width:  var(--mw-width-progress-indicator-small);
            height: var(--mw-height-progress-indicator-small);
        }
        &[size="medium"] {
           width:  var(--mw-width-progress-indicator-medium);
            height: var(--mw-height-progress-indicator-medium);
        }
        &[size="large"] {
           width:  var(--mw-width-progress-indicator-large);
            height: var(--mw-height-progress-indicator-large);
        }
        &[size="xlarge"] {
            width:  var(--mw-width-progress-indicator-xlarge);
            height: var(--mw-height-progress-indicator-xlarge);
        }

    }